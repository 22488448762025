// @flow
import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { useTheme, useMediaQuery, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import withStyles from '@mui/styles/withStyles'

import NavigationBar from 'components/molecules/navigationBar'
import SideBar from 'components/molecules/sideBar'
import EnvBanner from 'components/atoms/envBanner'
import GlobalNotification from 'components/atoms/globalNotification'
import { PoweredByRCPAQAP } from 'components/atoms/corporationIcon'
import ErrorBoundary, { ContentErrorBoundary } from 'components/molecules/errorBoundary'
import AcceptingCookiesAlert from 'components/atoms/acceptingCookiesAlert'
import AppVersion from 'components/molecules/appVersion'

import PreferenceTour from '../../tours/preferenceTour'
import { ENV_BANNER_ENABLED } from 'qap/constants'
import { styles } from './styles'

import type { Node } from 'react'

type Props = {
  children: Node,
  classes: Object
}

const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
`

const storageKey = 'openSidebar'
const getStore = () => JSON.parse(localStorage.getItem(storageKey) || 'true')
const setStore = (val) => localStorage.setItem(storageKey, JSON.stringify(val))
const defaultOpenState = (isWideScreen) => isWideScreen ? getStore() : false

export const CommentTabContext = React.createContext<any>()

const HeaderSideBarLayout = (props: Props) => {
  const { classes, children } = props
  const theme = useTheme()
  const isWideScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const [openSidebar, setOpenSideBar] = useState(false)
  const [commentsCollapsed, setCommentsCollapsed] = useState(null)

  useEffect(
    () => {
      setOpenSideBar(defaultOpenState(isWideScreen))
    }, [isWideScreen]
  )

  const handleDrawerToggle = () => {
    if (isWideScreen) {
      setStore(!openSidebar)
    }

    setOpenSideBar(!openSidebar)
  }

  return (
    <div className={classes.root}>
      <EnvBanner />
      <GlobalNotification />
      <PreferenceTour />
      <NavigationBar
        className={props.classes.header}
        onDrawerToggle={handleDrawerToggle}
        openSidebar={openSidebar}
      />
      <SideBar
        openSidebar={openSidebar}
        onDrawerToggle={handleDrawerToggle}
      />

      <main className={classnames(classes.main, { [classes.drawerOpen]: openSidebar, [classes.drawerClose]: !openSidebar })}>
        <div className={classes.toolbar} />
        <div className={classnames(classes.print, classes.content, classes.contentPadding, {
          [classes.envBannerMargin]: ENV_BANNER_ENABLED
        })}>
          <ErrorBoundary component={ContentErrorBoundary}>
            <CommentTabContext.Provider value={{
              commentsCollapsed,
              setCommentsCollapsed
            }}>
              {children}
            </CommentTabContext.Provider>
          </ErrorBoundary>
        </div>
      </main>
      <AcceptingCookiesAlert />
      <footer className={
        classnames(classes.footer, {
          [classes.expandedCommentPadding]: commentsCollapsed === false,
          [classes.collapsedCommentPadding]: commentsCollapsed
        })
      }>
        <FooterContainer>
          <Box>
            <PoweredByRCPAQAP className={classes.poweredBy} />
          </Box>
          <AppVersion />
        </FooterContainer>
      </footer>
    </div>
  )
}

export default withStyles(styles)(HeaderSideBarLayout)
