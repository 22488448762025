// @flow
import newRelicAgent from 'utilities/monitoring/newRelic'
import sentry from 'utilities/monitoring/sentry'

const init = () => {
  newRelicAgent.init()
  sentry.init()
}

const noticeError = (error: any = new Error('myQAP Front-end Portal'), attributes: any = {}) => {
  newRelicAgent.noticeError(error, attributes)
  sentry.noticeError(error, attributes)
}

const setAPIReleaseVersion = (apiVersion: string = '') => {
  newRelicAgent.setAPIReleaseVersion(apiVersion)
  sentry.setAPIReleaseVersion(apiVersion)
}

export default {
  init,
  noticeError,
  setAPIReleaseVersion,
}
