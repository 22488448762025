// @flow
import * as actions from './actions'
import type { Action } from '../types'
import type { OpenApiSpecification, OperationSpec, Response } from './types'
import { success } from 'utilities/actions'

export type State = {
  specification: OpenApiSpecification,
  permissionsByOperationId: { [string]: Array<string> },
  systemStatus: { apiVersion: string },
}

const permissionsByOperationId = (specification: OpenApiSpecification | {}): Response => {
  let permissionsByOperationId = {}

  if (!specification.paths || typeof specification.paths !== 'object') {
    return permissionsByOperationId
  }

  Object.values(specification.paths).forEach(pathSpec => {
    if (pathSpec && typeof pathSpec === 'object') {
      Object.values(pathSpec).forEach((operationSpec: OperationSpec | mixed) => {
        if (operationSpec && operationSpec.operationId) {
          const operationId = operationSpec.operationId

          if (typeof operationId === 'string') {
            permissionsByOperationId[operationId] = operationSpec.xPermissions || []
          }
        }
      })
    }
  })

  return permissionsByOperationId
}

export const initialState = {
  specification: {},
  permissionsByOperationId: {},
  systemStatus: {}
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) state = initialState
  switch (action.type) {
    case actions.FETCH_API_SPECIFICATION:
      return initialState
    case actions.FETCH_API_SPECIFICATION_SUCCESS:
      let { type, ...specification } = action
      return {
        ...state,
        specification,
        permissionsByOperationId: permissionsByOperationId(specification)
      }
    case success(actions.FETCH_SYSTEM_STATUS):
      let { release: apiVersion } = action

      return {
        ...state,
        systemStatus: {
          ...state.systemStatus,
          apiVersion
        }
      }
    default:
      return state
  }
}

export default reducer
