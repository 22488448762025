// @flow
import React from 'react'
import { styled } from '@mui/material/styles'
import { Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import { APP_RELEASE_VERSION } from 'qap/constants'

type AppVersionStyles = {
  color?: ?string,
}

type AppVersionType = {
  label: string,
  description: ?string,
  show: boolean,
}

const StyledStack = styled(Stack)(({ theme, styles = {} }) => ({
  alignItems: 'flex-end',
  '& .MuiTypography-root': {
    color: styles.color || theme.palette.grey[600],
    fontSize: theme.typography.caption.fontSize,
  },
}))

const AppVersion = ({ styles }: { styles?: AppVersionStyles }) => {
  const { apiVersion } = useSelector((state) => state.api.systemStatus) || {}
  const APP_VERSIONS: Array<AppVersionType> = [
    { label: 'myQAP', description: APP_RELEASE_VERSION, show: Boolean(APP_RELEASE_VERSION) },
    { label: 'API', description: apiVersion, show: Boolean(apiVersion) },
  ]

  return (
    <StyledStack styles={styles}>
      {APP_VERSIONS.map(
        ({ label, description, show }) =>
          show && (
            <Typography key={label}>
              {label} {description}
            </Typography>
          ),
      )}
    </StyledStack>
  )
}

export default AppVersion
