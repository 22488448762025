// @flow
import { takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import { success } from 'utilities/actions'
import AppMonitoring from 'utilities/monitoring'

function* updatedSystemStatus(action): Generator<any, any, any> {
  const { release: apiVersion } = action

  yield AppMonitoring.setAPIReleaseVersion(apiVersion)
}

export default function* systemStatusSagas(): Generator<any, any, any> {
  yield takeLatest(success(actions.FETCH_SYSTEM_STATUS), updatedSystemStatus)
}
