// @flow
import {
  API_ROOT,
  API_URL,
  SYSTEM_STATUS
} from 'qap/constants'
import { failure, success } from 'utilities/actions'

import { requestActions } from 'state/request'

const resource = (strings: Array<string>) => {
  return strings.join('/')
}

export const FETCH_API_SPECIFICATION = 'FETCH_API_SPECIFICATION'
export const FETCH_API_SPECIFICATION_SUCCESS = 'FETCH_API_SPECIFICATION_SUCCESS'
export const FETCH_API_SPECIFICATION_FAILURE = 'FETCH_API_SPECIFICATION_FAILURE'
export const FETCH_SYSTEM_STATUS = 'FETCH_SYSTEM_STATUS'

export const fetchApiSpecification = () => {
  return {
    type: requestActions.UNAUTHED_REQUEST,
    method: 'GET',
    url: resource([API_URL, API_ROOT]),
    successAction: FETCH_API_SPECIFICATION_SUCCESS,
    failureAction: FETCH_API_SPECIFICATION_FAILURE
  }
}

export const fetchSystemStatus = () => {
  return {
    type: requestActions.UNAUTHED_REQUEST,
    method: 'GET',
    url: resource([API_URL, SYSTEM_STATUS]),
    successAction: success(FETCH_SYSTEM_STATUS),
    failureAction: failure(FETCH_SYSTEM_STATUS)
  }
}
