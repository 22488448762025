// @flow
import reducer from './reducer'
import * as apiActions from './actions'
import type { State } from './reducer'
import systemStatusSagas from './sagas'

export {
  apiActions,
  systemStatusSagas,
}

export type ApiState = State

export default reducer
